import { FC } from "react";

const ArrowIcon:FC<{ className?: string }> = ({ className }): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="10"
    viewBox="0 0 11 10"
    fill="none"
    className={className}>
    {/* eslint-disable-next-line max-len */}
    <path d="M6.06044 6.68555C5.67136 6.94953 5.13748 6.90914 4.79271 6.56437L1.96428 3.73594C1.57376 3.34542 1.57376 2.71225 1.96428 2.32173C2.3548 1.9312 2.98797 1.9312 3.37849 2.32173L5.49982 4.44305L7.62117 2.3217C8.01169 1.93118 8.64486 1.93118 9.03538 2.3217C9.4259 2.71223 9.4259 3.34539 9.03538 3.73592L6.20695 6.56434C6.20207 6.56923 6.19714 6.57406 6.19218 6.57882C6.15067 6.61868 6.10658 6.65425 6.06044 6.68555Z" fill="#232325" />
  </svg>
);

export default ArrowIcon;
